import React, { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby';

import * as webSocketActions from '../../../redux/actions/webSocketActions';

import './odometer-theme.css';
import '../index.css';
import './index.css';
import { ProviderLogos } from '../../Landing';
import ThousandKWhTrap from '../../LandingSC/ThousandKWhTrap';

function Article({ siteConfig, serviceAddress }) {
  const timeout = useRef(null);
  const [value, setValue] = useState(0);
  const [showTrap, setShowTrap] = useState(false);
  const odometerRef = useRef(null);
  const trapTitleRef = useRef(null);

  useEffect(() => {
    // Simulate odometer value update
    timeout.current = setTimeout(() => setValue(661), 1000);

    // Clean up timeout on unmount
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, []);

  useEffect(() => {
    if (odometerRef.current) {
      const loadOdometer = async () => {
        const Odometer = (await import('odometer')).default;
        const odometer = new Odometer({ el: odometerRef.current, value: 0 });
        odometer.update(661);
      };

      loadOdometer();
    }
  }, []);

  useEffect(() => {
    // Simulate odometer value update
    timeout.current = setTimeout(() => setValue(661), 1000);

    // Clean up timeout on unmount
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const element = trapTitleRef.current;
      if (!element) return;

      const rect = element.getBoundingClientRect();
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;

      // Check if the element's top has passed the halfway point of the viewport
      if (rect.top < viewportHeight / 2) {
        setShowTrap(true);
      }
    };

    // Attach scroll listener
    window.addEventListener('scroll', handleScroll);

    // Clean up on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="article-container">
      <h1>
        <div>
          What every <strong>Apartment Resident </strong>
          should know before choosing an electricity plan.
        </div>
      </h1>
      <div className="page-container text-container full sc-landing need-to-know">
        <h5 className="est-read-time">
          Published: 11/16/2024 | Read time {'< '}10 min
        </h5>

        <div className="page">
          <div className="inner">
            <div className="article-text" style={{ width: '100%' }}>
              <h3>
                <div className="meter">
                  {/* <Odometer value={value} format="(.ddd),dd" /> */}
                  <div ref={odometerRef} />
                </div>{' '}
                kilowatts
              </h3>
              <h2 style={{ textAlign: 'center' }}>
                That’s what the average apartment uses in electricity each
                month.
              </h2>
              <p style={{ marginTop: '20px' }}>
                <strong>Why it matters</strong> – If you live in an apartment
                and are comparing electricity plans based on the 1000 kWh rate
                listed on each plan’s Electricity Facts Label (the rate most
                comparison sites use to rank plans), you might be in for an
                unpleasant surprise when you get your first bill. This is
                because some plans achieve their low rates by offering ‘bill
                credits’ that only apply once you hit a minimum of 1000 kWh of
                usage.
              </p>

              <h3 id="trapTitle" ref={trapTitleRef}>
                Don’t fall for the 1000 kWh pricing trap!
              </h3>

              <ThousandKWhTrap
                article
                noText
                waitForTrap={true}
                showTrap={showTrap}
              />

              <h2 style={{ textAlign: 'center' }}>
                The average apartment uses less than 1000 kWh each month.
              </h2>

              <div className="top-image">
                <img
                  src={withPrefix(
                    '/images/articles/need-to-know/chart-usage.png'
                  )}
                  alt="Usage Table"
                />
                <img
                  src={withPrefix(
                    '/images/articles/need-to-know/chart-average.png'
                  )}
                  alt="Usage Over Time"
                />
              </div>

              <p>
                Let’s look at a real-world example to show why a ‘bill credit’
                plan might not be ideal for apartment residents. While this plan
                had one of the lowest advertised rates at exactly 1000 kWh
                (10.5¢ per kWh), it charged one of the highest rates (20.7¢ per
                kWh) for usage below that threshold. The low 10.5¢ rate only
                applied because of a $100 bill credit that activated once the
                usage hit exactly 1000 kWh
              </p>
              <p>
                <strong>
                  The issue for most apartment residents is that their monthly
                  usage is usually well below the 1000 kWh threshold required to
                  qualify for the $100 credit. As a result, they don't receive
                  the credit and end up paying a much higher rate than the one
                  advertised - in this example, nearly twice as much.
                </strong>
              </p>

              <img
                src={withPrefix('/images/articles/need-to-know/plan-card.png')}
                alt="Plan Card"
                className="plan"
              />

              <h3 className="plan-image-label up hand">
                “Advertised” rate at 1000 kWh: 10.5¢{' '}
                <img
                  src={withPrefix('/images/arrows/arrow_22-short.svg')}
                  alt="Plan View"
                  className="plan"
                />
              </h3>
              <h3 className="plan-image-label hand">
                Actual rate at 660 kWh: 20.7¢
                <img
                  src={withPrefix('/images/arrows/arrow_22-short.svg')}
                  alt="Plan View"
                  className="plan"
                />
              </h3>

              <img
                src={withPrefix('/images/articles/need-to-know/plan-view.png')}
                alt="Plan View"
                className="plan"
              />

              <div className="bottom-text">
                <h2>The SmarterChoice SOLUTION </h2>
                <p>
                  With SmarterChoice, you can easily avoid the 1000 kWh trap by
                  using our filters to adjust the results, so the rates are
                  based on the <strong>Average Apartment</strong> usage of 661
                  kWh instead of the standard 1000 kWh.
                </p>
                <p>
                  You can also choose to{' '}
                  <strong>
                    Show ONLY Fixed Rate Plans – No Pricing Gimmicks
                  </strong>
                  , which will eliminate all ‘bill credit’ plans from the
                  results entirely.
                </p>
              </div>

              <div className="bottom-image">
                <img
                  src={withPrefix('/images/articles/need-to-know/filter.png')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Article);
