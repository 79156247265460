import React from 'react';
import SEO from '../components/SEO';

import Article from '../components/Articles/NeedToKnow/index';

export default function ({}) {
  return (
    <>
      <SEO
        title={'Need to Know - Texas Electricity for Apartment Residents'}
        description={
          'What every Apartment Resident should know before choosing an electricity plan.'
        }
        brand={'sc'}
      />

      <Article />
    </>
  );
}
